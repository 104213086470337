<style lang="scss">
.copy-input {
  border: none;
}
</style>
<!--eslint-disable-->
<template>
  <div class="users-page">
    <b-card class="" title="Manage Keys">
      <b-card-text>
        This page allows you to create and manage keys for the MDM Electron
        system.
      </b-card-text>
    </b-card>

    <b-card class="mb-0" no-body>
      <div v-if="$can('Create', 'Keys') && $can('Manage', 'All')" class="m-2">
        <b-row>
          <b-col
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            cols="12"
            md="6"
          >
            <b-button variant="primary" @click="$bvModal.show('modal-add-key')"
              >Create Key
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="keys.length > 0"
        :current-page="currentPage"
        :fields="headers"
        :items="keys"
        :per-page="0"
        class="data-table"
        hover
      >
        <template #cell(policy_id)="data">
          {{ data.item.policy ? data.item.policy.name : "None" }}
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | formatDateTime }}
        </template>

        <template #cell(uid)="data">
          <input
            :id="data.item.uid"
            :value="data.item.uid"
            class="copy-input mr-1"
            type="text"
          /><i
            class="fas fa-copy text-success"
            @click="copyKey(data.item.uid)"
          ></i>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            class="m-0"
            no-caret
            right
            toggle-class="text-decoration-none"
            variant="link"
          >
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item-button
              v-if="$can('Update', 'Keys')"
              class="full-nested-btn"
              @click="showKeyModal(data.item, 'modal-update-key')"
            >
              Update key
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="$can('Delete', 'Keys')"
              class="full-nested-btn"
              @click="showKeyModal(data.item, 'modal-delete-key')"
            >
              Delete key
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>

      <p v-if="!loading && keys.length === 0" class="ml-2 mt-1">
        No Keys Found
      </p>
    </b-card>

    <b-modal id="modal-add-key" hide-footer title="Add new key">
      <manage-key
        key="add-key"
        @close="closeModals()"
        @refresh="refreshKeys()"
      ></manage-key>
    </b-modal>

    <b-modal id="modal-update-key" hide-footer title="Update key">
      <manage-key
        key="update-key"
        :existing-key="selectedKey"
        @close="closeModals()"
        @refresh="refreshKeys()"
      ></manage-key>
    </b-modal>

    <b-modal id="modal-delete-key" hide-footer title="Delete key">
      <delete-modal
        v-if="selectedKey"
        :subtitle="selectedKey.ip_ranges"
        title="Are you sure you wish to delete key with IP range"
        @close="closeModals()"
        @delete="deleteKey(selectedKey)"
      ></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import KeysService from "@/services/KeysService";
import ManageKey from "@/views/keys/sections/ManageKey.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import { mapState } from "vuex";

export default {
  name: "Keys",
  components: {
    ManageKey,
    DeleteModal,
  },
  props: {},
  data() {
    return {
      headers: [
        {
          key: "uid",
          label: "Key",
        },
        {
          key: "label",
          label: "Label",
        },
        {
          key: "enabled",
          label: "Enabled",
        },
        {
          key: "ip_ranges",
          label: "IP Ranges",
        },
        {
          key: "policy_id",
          label: "Policy",
        },
        {
          key: "created_at",
          label: "Created At",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      keys: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        search: "",
      },
      total_count: 0,
      loading: true,
      selectedKey: null,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.currentUser,
    }),
  },
  mounted() {
    this.getKeys();
  },
  methods: {
    refreshKeys() {
      this.closeModals();
      this.getKeys();
    },
    closeModals() {
      this.$bvModal.hide("modal-add-key");
      this.$bvModal.hide("modal-delete-key");
      this.$bvModal.hide("modal-update-key");
    },
    getKeys() {
      KeysService.getKeys()
        .then(res => {
          this.keys = res.data;
        })
        .catch(err => {
          const res = err.response;
          let errorText = "Could not get keys, please refresh and try again";

          if (res && res.data.error) {
            errorText = res.data.error;
          }

          this.$toast.error(errorText, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    copyKey(key) {
      // This is only available in secure contexts. This is a replacement for execCommand below
      if (navigator?.clipboard) {
        navigator.clipboard.writeText(key);
      }

      const input = document.getElementById(`${key}`);
      input.select();
      document.execCommand("copy");
      this.$toast.success("Copied");
    },
    showKeyModal(key, modalName) {
      this.selectedKey = key;
      this.$bvModal.show(modalName);
    },
    deleteKey(key) {
      KeysService.deleteKey(key.uid)
        .then(() => {
          this.$toast.success(`Deleted key ${key.ip_ranges} Successfully`, {
            toastClassName: ["toast-std", "success-toast"],
          });

          this.refreshKeys();
        })
        .catch(err => {
          const res = err.response;
          let errorText = "Could not delete key, please refresh and try again";

          if (res && res.data.error) {
            errorText = res.data.error;
          }

          this.$toast.error(errorText, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        });
    },
  },
};
</script>
