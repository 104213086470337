<style lang="scss"></style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(manageKey)">
        <section class="mb-2">
          Keys are created using CIDR notation e.g 80.80.102.44/32.
        </section>
        <section class="mb-2">
          <div>
            <label>IP Range</label>
            <validation-provider
              v-slot="validationContext"
              :rules="{ required: true }"
              name="Identifier"
            >
              <b-form-input
                v-model="key.ip_ranges"
                :state="getValidationState(validationContext)"
                class="mb-1"
                placeholder="e.g. 0.0.0.0/0"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <div class="mb-2">
              <label>Policy</label>
              <eapi-policy-search-select
                :selected="selectedPolicy"
                @change="updateSelectedPolicy"
              />
            </div>

            <label>Label</label>
            <br>
            <small>This is an optional tag to show where the key is being
              used</small>
            <b-form-input
              v-model="key.label"
              class="mb-1"
              placeholder="e.g. East Wing"
            />

            <b-form-checkbox v-model="key.enabled" name="check-button" switch>
              Key Enabled
            </b-form-checkbox>
          </div>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button v-if="!existingKey" type="submit" variant="primary">Add new key
          </b-button>
          <b-button v-else type="submit" variant="primary">Update key</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import KeysService from "@/services/KeysService";
import EapiPolicyService from "@/services/EapiPolicyService";
import EapiPolicySearchSelect from "@/components/input/PaginatedSearchSelect/EapiPolicySearchSelect.vue";

export default {
  name: "MangeKey",
  components: {
    EapiPolicySearchSelect,
  },
  props: {
    existingKey: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      key: {
        ip_ranges: "",
        enabled: true,
        label: "",
        policy_id: 0,
      },
      selectedPolicy: null,
    };
  },
  async mounted() {
    if (this.existingKey) {
      Object.assign(this.key, this.existingKey);
      if (this.key.policy_id !== 0) this.getKeyPolicy();
    }
  },
  methods: {
    getKeyPolicy() {
      // Check we have permission to get the policies
      if (!this.$can("Manage", "All")) {
        return;
      }
      EapiPolicyService.getPolicy(this.key.policy_id)
        .then(res => {
          this.selectedPolicy = res.data;
        })
        .catch(err => {
          const res = err.response;
          let errorText =
            "Could not get policy for key, please refresh and try again";

          if (res && res.data.error) {
            errorText = res.data.error;
          }

          this.$toast.error(errorText, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        });
    },
    manageKey() {
      if (this.existingKey) {
        this.updateKey();
      } else {
        this.addKey();
      }
    },
    addKey() {
      KeysService.createKey(this.key)
        .then(() => {
          this.$toast.success("Added key Successfully", {
            toastClassName: ["toast-std", "success-toast"],
          });

          this.$emit("refresh", true);
        })
        .catch(err => {
          const res = err.response;
          let errorText = "Could not add key, please refresh and try again";

          if (res && res.data.error) {
            errorText = res.data.error;
          }

          this.$toast.error(errorText, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        });
    },
    updateKey() {
      KeysService.updateKey(this.existingKey.uid, this.key)
        .then(() => {
          this.$toast.success("Updated key Successfully", {
            toastClassName: ["toast-std", "success-toast"],
          });

          this.$emit("refresh", true);
        })
        .catch(err => {
          const res = err.response;
          let errorText = "Could not update key, please refresh and try again";

          if (res && res.data.error) {
            errorText = res.data.error;
          }

          this.$toast.error(errorText, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    updateSelectedPolicy(policy) {
      this.selectedPolicy = policy;
      if (!this.selectedPolicy) {
        this.key.policy_id = null
      } else {
        this.key.policy_id = this.selectedPolicy.id;
      }
    }
  },
};
</script>
